import { createSelector } from 'reselect';

import { GetSettingsResponse } from 'models/settings';
import { ReduxState } from 'ducks';

export interface FooterSettingsShape {
  phone: string;
  email: string;
  address: string;
  operatingHoursDescription: string;
  agentName: string;
  agentLogoURL: string;
  snsIds: { key: string; id: string }[];
  showJapaneseTerms: boolean;
  showTravelAgencyRegistrationForm: boolean;
}

export const toFooterSettingsShape = (
  settingsResponse: GetSettingsResponse
): FooterSettingsShape => {
  let operatingHoursDescription = '';
  const operatingHoursRules = settingsResponse.operating_hours_rules;
  if (operatingHoursRules && operatingHoursRules.length > 0) {
    // TODO: localization
    operatingHoursDescription = `${operatingHoursRules[0].start_time_local} - ${operatingHoursRules[0].end_time_local}`;
  }

  return {
    phone: settingsResponse.supplier_phone || '',
    email: settingsResponse.supplier_email || '',
    address: settingsResponse.supplier_address || '',
    operatingHoursDescription,
    agentName: settingsResponse.agent_name || '',
    agentLogoURL: settingsResponse.agent_logo_url || '',
    snsIds: settingsResponse.sns_ids || [],
    showJapaneseTerms: !settingsResponse.booking_widget_design_params
      ?.hide_footer_japanese_terms_link,
    showTravelAgencyRegistrationForm:
      settingsResponse.reservation_payment_gateway_settings?.payment_gateway === 'GMO',
  };
};

export const selectFooterSettings = createSelector(
  (state: ReduxState) => state.server.settings.all,
  (settings) => toFooterSettingsShape(settings)
);
