import * as React from 'react';
import { ThemeName } from 'components/Themes';
import { BookingWidgetThemeContext } from 'contexts/BookingWidgetThemeContext';

interface Props {
  snsIds: { key: string; id: string }[];
}

const getIconPath = (bookingWidgetTheme: ThemeName, snsKey: string): string => {
  switch (bookingWidgetTheme) {
    case 'COOL_PROFESSIONAL':
      return `/static/images/coolProfessional/ic_${snsKey}.svg`;
    default:
      return `/static/images/ic_${snsKey}.svg`;
  }
};

const snsIconLink = [
  {
    key: 'facebook',
    href: 'https://www.facebook.com/__ID__',
  },
  {
    key: 'twitter',
    href: 'https://twitter.com/__ID__',
  },
  {
    key: 'instagram',
    href: 'https://www.instagram.com/__ID__/',
  },
  {
    key: 'youtube',
    href: 'https://www.youtube.com/channel/__ID__',
  },
  {
    key: 'line',
    href: '__ID__',
  },
];

export const SnsIcons = ({ snsIds }: Props) => {
  const bookingWidgetTheme = React.useContext(BookingWidgetThemeContext);

  return (
    <div className="c-sns__icons">
      {snsIds &&
        snsIds.length > 0 &&
        snsIconLink
          .filter((link) => {
            return snsIds.some((snsId) => {
              return snsId.key === link.key;
            });
          })
          .map((link) => {
            const snsId = snsIds.find((snsId) => snsId.key === link.key);
            return (
              <div key={snsId?.key || ''} className="c-sns__icon">
                <a
                  href={link.href.replace('__ID__', snsId?.id || '')}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={getIconPath(bookingWidgetTheme, snsId?.key || '')}
                    width="30"
                    height="30"
                    alt={snsId?.key || ''}
                  />
                </a>
              </div>
            );
          })}
    </div>
  );
};
