import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ReduxState } from 'ducks';

import { FooterLink } from 'models/settings';

const toContentLanguage = (isoLang: string) => isoLang.toUpperCase().replace('-', '_');

export const useCustomFooterLinks = (): FooterLink[] => {
  const footerLinkLayouts = useSelector(
    (state: ReduxState) =>
      state.server.settings.all.booking_widget_design_params?.footer_link_layouts
  );

  const { i18n } = useTranslation();

  return (
    footerLinkLayouts?.find((layout) => layout.language === toContentLanguage(i18n.language))
      ?.footer_links ?? []
  );
};
